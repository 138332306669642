import React, { createContext, useState, useEffect, ReactNode, ReactElement } from 'react';
import { ethers } from 'ethers';
import { BlockWithTransactions, TransactionResponse } from '@ethersproject/abstract-provider/lib/index.d';

interface DataContextType {
    blocksData: BlockWithTransactions[];
    transactions: TransactionResponse[];
    getBlockInformation: () => Promise<void>;
}

const defaultContextValue: DataContextType = {
    blocksData: [],
    transactions: [],
    getBlockInformation: async () => { }
};

export const DataContext = createContext<DataContextType>(defaultContextValue);

interface DataContextProviderProps {
    children: ReactNode;
}

export const DataContextProvider: React.FC<DataContextProviderProps> = ({ children }): ReactElement | null => {
    const [blocksData, setBlocksData] = useState<BlockWithTransactions[]>([]);
    const [transactions, setTransactions] = useState<TransactionResponse[]>([]);

    const fetchBlocks = async () => {
        const provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_JSON_RPC_URL);
        const blockNumber = await provider.getBlockNumber();
        // const blockNumber = 16170

        for (let i = 0; i < 5; i++) {
            const block = await provider.getBlockWithTransactions(blockNumber - i);
            setBlocksData((prevData) => {
                return [...prevData, block];
            });
            setTransactions((prevData) => {
                return [...prevData, ...block.transactions];
            });
        }
    };

    return (
        <DataContext.Provider value={{ blocksData, transactions, getBlockInformation: fetchBlocks }}>
            {children}
        </DataContext.Provider>
    );
};
