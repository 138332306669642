import React, { useState } from 'react';

type AddressDisplayProps = {
  address: string;
  length?: number;  // 表示する先頭・末尾の文字数を指定するオプションのパラメータ
};

const AddressDisplay: React.FC<AddressDisplayProps> = ({ address, length = 10 }) => {
  const [isHovered, setIsHovered] = useState(false);  // ホバー状態を管理するステート

  // 先頭と末尾を表示する関数
  const displayShortenedAddress = (addr: string, len: number) => {
    // アドレスの長さが指定された長さの2倍+3（"..."の分）以下なら全体を表示
    if (addr.length <= len * 2) {
      return addr;
    }
    // 先頭 `len` 文字 + 省略符号 + 末尾 `len` 文字
    return `${addr.slice(0, len)}...${addr.slice(-len)}`;
  };

  return (
    <span
      className="relative cursor-pointer"  // Tailwind CSS クラスを使用
      onMouseEnter={() => setIsHovered(true)}  // ホバー開始時に表示
      onMouseLeave={() => setIsHovered(false)}  // ホバー解除時に非表示
    >
      {/* address の先頭と末尾を表示、デフォルトでは 6 文字ずつ */}
      {displayShortenedAddress(address, length)}  

      {/* ホバー時に全体表示するツールチップ */}
      {isHovered && (
        <span
          className="absolute bottom-[150%] left-1/2 transform -translate-x-1/2 bg-black text-white px-2 py-1 rounded-md text-sm whitespace-nowrap z-10"
        >
          {address}  {/* アドレスの全体を表示 */}
        </span>
      )}
    </span>
  );
};

export default AddressDisplay;
