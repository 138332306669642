import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { ethers } from "ethers";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import Logs from "./Logs";
import { TransactionReceipt, TransactionResponse } from '@ethersproject/abstract-provider';

export interface TokenInfo {
  name: string;
  decimals: number;
}

const getTokenInfo = async (provider: ethers.providers.JsonRpcProvider, contractAddress: string) => {
  try {
    const erc20Abi = [
      'function name() view returns (string)',
      'function decimals() view returns (uint8)',
    ];
    const contract = new ethers.Contract(contractAddress, erc20Abi, provider);
    const name = await contract.name();
    const decimals = await contract.decimals();
    return { name, decimals };
  } catch (error) {
    console.error('Error fetching token info:', error);
    return null;
  }
};

function Transaction() {
  const [txReceipt, setTxReceipt] = useState<TransactionReceipt | null>(null);
  const [txResponse, setTxResponse] = useState<TransactionResponse | null>(null);
  const [tokenInfo, setTokenInfo] = useState<TokenInfo | null>(null);

  const { hash } = useParams<{ hash: string }>();

  useEffect(() => {
    const getTransaction = async () => {
      try {
        const provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_JSON_RPC_URL);
        
        // Get transaction receipt and response
        const receipt = await provider.getTransactionReceipt(hash);
        const response = await provider.getTransaction(hash);

        setTxReceipt(receipt);
        setTxResponse(response);

        // Get token info if `to` is a contract address
        if (receipt && receipt.to) {
          const tokenInfo = await getTokenInfo(provider, receipt.to);
          setTokenInfo(tokenInfo);
        }
      } catch (error) {
        console.error("Error fetching transaction details:", error);
        setTxReceipt(null);
      }
    };

    getTransaction();
  }, [hash]);

  if (!txReceipt || !txResponse) {
    return <div className="text-center">Loading...</div>;
  }

  return (
    <div className="bg-white mx-24 px-8 py-4 my-8 border rounded-lg divide-y">
      <h1 className="pb-3 text-[#3498DA] font-bold">Overview</h1>
      <div className="flex">
        <div className="w-1/2 divide-y">
          <TitleComponent title="Transaction Hash" />
          <TitleComponent title="Block Number" />
          <TitleComponent title="Block Hash" />
          <TitleComponent title="From" />
          <TitleComponent title="To" />
          <TitleComponent title="Gas Limit & Usage by Txn" />
          <TitleComponent title="Value (ETH)" />
          <TitleComponent title="Token Name" />
        </div>
        <div className="divide-y w-full">
          <p className="py-6">{txReceipt.transactionHash}</p>
          <p className="py-6 text-[#357BAD]">
            <Link to={`/block/${txReceipt.blockNumber}`}>{txReceipt.blockNumber}</Link>
            <span className="bg-gray-100 text-xs text-gray-500 p-2 ml-3 rounded-md">
              {txReceipt.confirmations} Block Confirmations
            </span>
          </p>
          <p className="py-6 text-[#357BAD]">
            <Link to={`/block/${txReceipt.blockHash}`}>{txReceipt.blockHash}</Link>
          </p>
          <p className="py-6">{txReceipt.from}</p>
          <p className="py-6">{txReceipt.to}</p>
          <p className="py-6">{txReceipt.gasUsed.toString()}</p>
          <p className="py-6">{ethers.utils.formatEther(txResponse.value)} ETH</p>
          <p className="py-6">{tokenInfo?.name}</p>
        </div>
      </div>
      <Logs logs={txReceipt.logs} decimals={tokenInfo?.decimals || 2}/>
    </div>
  );
}

function TitleComponent({ title }: { title: string }) {
  return (
    <div className="flex items-center">
      <AiOutlineQuestionCircle />
      <p className="ml-2 py-6">{title}</p>
    </div>
  );
}

export default Transaction;
