import { atom } from 'jotai';
import { ethers } from 'ethers';
import { BlockWithTransactions, TransactionResponse } from '@ethersproject/abstract-provider';

// ブロックデータとトランザクションを管理する atom
export const blocksDataAtom = atom<BlockWithTransactions[]>([]);
export const transactionsAtom = atom<TransactionResponse[]>([]);

// プロバイダーを取得し、外部から参照できるようにする
export const providerAtom = atom(() => new ethers.providers.JsonRpcProvider(process.env.REACT_APP_JSON_RPC_URL));

// ブロック情報をフェッチする関数 atom
export const fetchBlocksAtom = atom(
  null,
  async (get, set) => {
    const provider = get(providerAtom);
    // データを取得する前に blocksDataAtom と transactionsAtom をクリア
    set(blocksDataAtom, []);
    set(transactionsAtom, []);

    const blockNumber = await provider.getBlockNumber();

    // 最新の5つのブロックとそのトランザクションを取得
    for (let i = 0; i < 5; i++) {
      const block = await provider.getBlockWithTransactions(blockNumber - i);

      // blocksData を更新
      set(blocksDataAtom, (prevData: BlockWithTransactions[]) => [...prevData, block]);

      // transactions を更新
      set(transactionsAtom, (prevData: TransactionResponse[]) => [...prevData, ...block.transactions]);
    }
  }
);
