import { atom } from 'jotai';
import { ethers } from 'ethers';
import { BlockWithTransactions, TransactionResponse } from '@ethersproject/abstract-provider';

// プロバイダーを取得し、外部から参照できるようにする atom
export const providerAtom = atom(() => new ethers.providers.JsonRpcProvider(process.env.REACT_APP_JSON_RPC_URL));

// 指定された blockNumberOrHash に基づくブロックデータを管理する atom
export const specificBlockDataAtom = atom<BlockWithTransactions | null>(null);
export const specificBlockTransactionsAtom = atom<TransactionResponse[]>([]);

// 特定のブロックをフェッチする関数 atom
export const fetchSpecificBlockAtom = atom(
  null,
  async (get, set, blockNumberOrHash: string | number) => {
    const provider = get(providerAtom);

    try {
      // `blockNumberOrHash` を判定して適切な形式に変換
      const parsedBlockNumberOrHash = parseBlockNumberOrHash(blockNumberOrHash);

      // 指定された blockNumberOrHash に対応するブロックを取得
      const block = await provider.getBlockWithTransactions(parsedBlockNumberOrHash);

      // 特定のブロックデータを更新
      set(specificBlockDataAtom, block);

      // 特定のブロックに含まれるトランザクションを更新
      set(specificBlockTransactionsAtom, block.transactions);
    } catch (error) {
      console.error('Failed to fetch block data:', error);

      // エラーが発生した場合、特定のブロックデータをリセット
      set(specificBlockDataAtom, null);
      set(specificBlockTransactionsAtom, []);
    }
  }
);

/**
 * `blockNumberOrHash` の値を判定して、number か hash に変換する関数
 * @param value - ブロック番号またはブロックハッシュ
 * @returns 数値またはハッシュとして解析した値
 */
const parseBlockNumberOrHash = (value: string | number): number | string => {
  // 数値の場合はそのまま返す
  if (typeof value === 'number') {
    return value;
  }

  // 数字の文字列かどうかを判定し、数値に変換できる場合は数値として返す
  if (/^\d+$/.test(value)) {
    return parseInt(value, 10);
  }

  // それ以外はハッシュ文字列として返す
  return value;
};
