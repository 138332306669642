import { useAtom } from "jotai";
import { Link, useLocation } from "react-router-dom";
import { transactionsAtom } from "../atoms/blocksAtoms";
import { fetchSpecificBlockAtom, specificBlockTransactionsAtom } from "../atoms/blockAtoms";
import { useEffect } from "react";
import AddressDisplay from "./AddressDisplay";

function Transactions() {
  // `jotai` の `useAtom` を使用して `blocksData` と `transactions` を取得
  const [transactions] = useAtom(transactionsAtom);
  const [transactionsInSpecificBlock] = useAtom(specificBlockTransactionsAtom);
  console.log("🚀 ~ Transactions ~ transactionsInSpecificBlock:", transactionsInSpecificBlock)
  const [, fetchSpecificBlock] = useAtom(fetchSpecificBlockAtom);

  const location = useLocation();
  // URL パラメータを取得してブロック番号を抽出
  const searchParams = new URLSearchParams(location.search);
  const blockNumber = searchParams.get("block");
  console.log("🚀 ~ Transactions ~ blockNumber:", blockNumber)

  const transactionsForView = blockNumber ? transactionsInSpecificBlock : transactions;

  useEffect(() => {
    if (blockNumber) {
      fetchSpecificBlock(blockNumber);
    }
  }, [blockNumber, fetchSpecificBlock]);

  return (
    <section className="bg-white mx-24 px-8 py-4 my-8 border rounded-lg divide-y">
      <div className="flex py-1 bg-sky-50">
        <p className="w-3/12 py-3 text-[#6C757E] font-bold">Txn Hash</p>
        <p className="w-2/12 py-3 text-[#6C757E] font-bold">Block</p>
        <p className="w-3/12 py-3 text-[#6C757E] font-bold">From</p>
        <p className="w-3/12 py-3 text-[#6C757E] font-bold">To</p>
        <p className="w-2/12 py-3 text-[#6C757E] font-bold">Data</p>
      </div>

      {/* トランザクションデータをコンポーネントとして表示 */}
      {transactionsForView.length ? (
        transactionsToComponent(transactionsForView)
      ) : (
        <div>No transactions found.</div>
      )}
    </section>
  );
}

// トランザクションを表示するコンポーネント
function transactionsToComponent(txs: any[]) {
  return txs.map((tx, index) => (
    <section key={index} className="flex py-4">
      <p className="w-3/12 text-[#357BAD]">
        <Link to={`/tx/${tx.hash}`}>{tx.hash.slice(0, 16)}...</Link>
      </p>
      <p className="w-2/12 text-[#357BAD]">
        <Link to={`/block/${tx.blockNumber}`}>{tx.blockNumber}</Link>
      </p>
      <p className="w-3/12">
        <AddressDisplay address={tx.from}  />
      </p>
      <p className="w-3/12">
      <AddressDisplay address={tx.to} /> 
      </p>
      <p className="w-2/12">{tx.data.slice(0, 16)}...</p>
    </section>
  ));
}

export default Transactions;
