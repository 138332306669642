import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { ethers } from "ethers";
import { BlockWithTransactions } from "@ethersproject/abstract-provider";
import { useAtom } from "jotai";
import { fetchSpecificBlockAtom, specificBlockDataAtom, specificBlockTransactionsAtom } from "../atoms/blockAtoms";

export interface BlockParams {
  blockNumberOrHash: string;
}

function Block() {
  const { blockNumberOrHash } = useParams<BlockParams>(); // URL パラメータを取得
  const [, fetchSpecificBlock] = useAtom(fetchSpecificBlockAtom);
  const [blockData] = useAtom(specificBlockDataAtom);

  useEffect(() => {
    if (blockNumberOrHash) {
      fetchSpecificBlock(blockNumberOrHash);
    }
  }, [blockNumberOrHash, fetchSpecificBlock]);

  if (!blockData) {
    return <div className="text-center">Loading...</div>;
  }

  return (
    <section>
      <h1 className="mx-24 my-8 text-xl text-gray-900">
        Block <span className="text-gray-500 text-lg ml-1">#{blockData.number}</span>
      </h1>
      <div className="bg-white mx-24 px-8 py-4 my-8 border rounded-lg divide-y">
        <h1 className="pb-3 text-[#3498DA] font-bold">Overview</h1>
        <div className="flex">
          <div className="w-1/5 divide-y">
            <TitleComponent title="Block" />
            <TitleComponent title="Time Stamp" />
            <TitleComponent title="Transactions" />
            <TitleComponent title="Fee Recipient" />
            <TitleComponent title="Gas Used" />
            <TitleComponent title="Gas Limit" />
            <TitleComponent title="Base Fee Per Gas" />
            <TitleComponent title="Extra Data" />
            <TitleComponent title="Hash" />
            <TitleComponent title="Parent Hash" />
            <TitleComponent title="Nonce" />
            <TitleComponent title="Difficulty" />
          </div>
          <div className="divide-y w-4/5">
            <p className="py-3">{blockData.number}</p>
            <p className="py-3">{blockData.timestamp}</p>
            <p className="py-3 text-[#357BAD]">
              <Link to={`/txs?block=${blockData.number}`}>
                {blockData.transactions ? blockData.transactions.length : 0} transactions
              </Link>
            </p>
            <p className="py-3">
              {blockData.miner}
            </p>
            <p className="py-3">{blockData.gasUsed.toString()}</p>
            <p className="py-3">{blockData.gasLimit.toString()}</p>
            <p className="py-3">{blockData.baseFeePerGas?.toString() || "Nan"}</p>
            <p className="py-3">{blockData.extraData.slice(0, 100)}...</p>
            <p className="py-3">{blockData.hash}</p>
            <p className="py-3 text-[#357BAD]">
              <Link to={`/block/${blockData.parentHash}`}>{blockData.parentHash}</Link>
            </p>
            <p className="py-3">{blockData.nonce}</p>
            <p className="py-3">{blockData.difficulty}</p>
          </div>
        </div>
      </div>
    </section>
  );
}

function TitleComponent({ title }: { title: string }) {
  return (
    <div className="flex items-center">
      <AiOutlineQuestionCircle />
      <p className="ml-2 py-3">{title}</p>
    </div>
  );
}

export default Block;
